import logo from './logo1.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>University of Alberta Computer Engineering Club</h2>
        <h3> Leave your name here by typing in some code!! </h3>
        <span style={{color: 'Pink'}}>yi meng is here right now</span>
        <span>luca</span>
        <span>massimo</span>
        <span>Violet</span>
        <span>aidan</span>
        <span>korbin</span>
        <span>Brett is cool</span>
        <span style = {{color:'Red'}}>melwin was here</span>
        <span>gaby</span>
        <span>avery</span>
        <span>misha</span>
        <span>vuokko</span>
      </header>
    </div>
  );
}

export default App;
